<template>
  <div>
    <h1>Product List</h1>
    <div v-for="product in products" :key="product.id" class="product">
      <h2>{{ product.name }}</h2>
      <p>Price: ${{ product.price }}</p>
      <p>{{ product.description }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const products = ref([]);

onMounted(() => {
  axios.get('https://internshipenes.000.pe/backend/db.php')
      .then(response => {
        products.value = response.data;
      })
      .catch(error => {
        console.error("There was an error fetching the products:", error);
      });
});
</script>

<style>
.product {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
}
</style>
